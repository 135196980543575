import {
  TxChartForHomePage,
  GenderChartForHomePage,
  CountryChartForHomePage,
  GenderChartByCategory,
  CountryChartByCategory,
} from "src/interfaces/chart";
import { ServiceBase } from "./core/service-base";
import {
  TxChartForHomePageParams,
  GenderChartForHomePageParams,
  GenderChartByCategoryParams,
} from "./params-type";

export class ChartService extends ServiceBase {
  getTxChartForHomePage = async (
    params?: TxChartForHomePageParams,
  ): Promise<{ data: TxChartForHomePage[] }> => {
    return this.get("/chart/trx-fee", params);
  };

  getGenderChartForHomePage = async (
    params?: GenderChartForHomePageParams,
  ): Promise<{ data: GenderChartForHomePage[] }> => {
    return this.get("/chart/user-registration/gender", params);
  };

  getCountryChartForHomePage = async (): Promise<{ data: CountryChartForHomePage[] }> => {
    return this.get("/chart/user-registration/nation");
  };

  getGenderChartByCategory = async (
    params?: GenderChartByCategoryParams,
    categoryId?: number,
  ): Promise<{ data: GenderChartByCategory[] }> => {
    return this.get(`/chart/scan-by-gender/${categoryId}`, params);
  };

  getCountryChartByCategory = async (
    categoryId?: number,
  ): Promise<{ data: CountryChartByCategory[] }> => {
    return this.get(`/chart/scan-by-countries/${categoryId}`);
  };
}
