import { Column, ColumnConfig } from "@ant-design/plots";
import { Skeleton } from "antd";

interface Props {
  data: Array<any> | null;
  chartLoading?: boolean;
}

const ColumnChart = (props: Props) => {
  const { data, chartLoading } = props;
  const config: ColumnConfig = {
    data: data as any,
    isGroup: true,
    xField: "time",
    yField: "value",
    seriesField: "type",
    xAxis: {
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: "#23262F",
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: "#23262F",
        },
      },
    },
    legend: {
      layout: "horizontal",
      position: "top-right",
      itemName: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: "#23262F",
        },
      },
    },
    color: ["#EB6368", "#F8CE2F", "#5EBCAA"],
    tooltip: {
      customContent: (title, items) => {
        if (items.length > 0) {
          return (
            <div className="custom-tooltip-column-chart">
              <p className="title">{title}</p>
              {items.map((value: any) => {
                const { color, data } = value;
                return (
                  <div className="content-value">
                    <div style={{ backgroundColor: color }} className="dot"></div>
                    <p className="value">
                      {data.type}:&nbsp;{data.value}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        }
      },
    },
  };
  return (
    <div>
      <Skeleton loading={chartLoading}>
        <Column {...config} />
      </Skeleton>
    </div>
  );
};

export default ColumnChart;
