import { ORDER_STATUS } from "src/constants";

export interface PageParams {
  page?: number;
  limit?: number;
  paginationMetadataStyle?: "body";
}
export interface PageQueryParams {
  page: number;
  perPage: number;
  paginationMetadataStyle: "body";
  [key: string]: string | number;
}

export interface CategoryParams extends PageParams {
  direction?: string;
  search?: string;
  sortBy?: string;
  isShowed?: boolean;
  hasParent?: boolean;
  parentId?: number;
  isAuMap?: boolean;
  isHKMap?: boolean;
  subCategoryId?: number;
  groupSearch?: string;
}

export interface AttributeParams extends PageParams {
  search_value?: string;
}

// user

export interface MerchantOnboardParams extends PageParams {
  company_name: string;
}

export interface MerchantParams extends PageParams {
  name: string;
}

export interface StoreParams extends PageParams {
  keyword?: string;
  category_ids?: Array<number>;
  sort_by?: string;
  order_by?: "ASC" | "DESC";
  is_featured?: boolean;
}
export interface OrdersParams extends PageParams {
  search_value?: string;
  status?: ORDER_STATUS;
}

export interface FiatParams {
  start_date: string;
  end_date: string;
  reportView: "weekly" | "monthly" | "yearly";
}

export interface ProductListParams {
  limit?: number;
  page?: number;
  search?: string;
  status?: number | string;
  tierType?: number;
  direction?: string;
  categoryId?: number | null;
  subCategoryId?: number;
  subCategoryIds?: number;
  groupId?: string | number | null;
  sortBy?: string;
  hasParent?: boolean;
  producerGroupId?: number;
  harvesterGroupId?: number;
}

export enum EChartUnit {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}

export interface TxChartForHomePageParams {
  startTime: string;
  endTime: string;
  unit: EChartUnit;
}

export interface GenderChartForHomePageParams extends TxChartForHomePageParams {}

export interface GenderChartByCategoryParams extends TxChartForHomePageParams {}

export interface CategoryData {
  logo?: string;
  name?: string;
  nutritionCategory?: number;
  origin?: number;
  isShowed?: boolean;
  categoryMedias?: CategoryMedias[];
  parentId: number;
  description?: string;
  calories?: string;
  protein?: string;
  totalFat?: string;
  saturatedFat?: string;
  monounsaturatedFat?: string;
  polyunsaturatedFat?: string;
  omega3FattyAcids?: string;
  cholesterol?: string;
  sodium?: string;
  potassium?: string;
  totalCarbohydrates?: string;
  fiber?: string;
  sugars?: string;
  vitaminD?: string;
  vitaminB12?: string;
  selenium?: string;
  fishMarkets?: FishMarkets[];
  categoryRecipes?: CategoryRecipes[];
  displayInAuMap?: boolean;
  displayInHkMap?: boolean;
}

export interface CategoryMedias {
  link?: string;
  type?: number;
  thumbnail?: string;
}

export interface FishMarkets {
  fishMarketId?: number;
  avg_price?: number;
  currency?: string;
}

export interface CategoryRecipes {
  link?: string;
  type?: number;
  name?: string;
  thumbnail?: string;
}

export interface RouteInfo {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  location: string;
  originLongitude: string;
  originLatitude: string;
  destinationLongitude: string;
  destinationLatitude: string;
  originRegion?: string;
  originRegionCode?: string;
  destinationRegion?: string;
  destinationRegionCode?: string;
  weight: number;
  description: string;
  cargoWiseIntegrate: boolean;
  cargoWiseProductId: number;
  creatorId: number;
  qrId: number;
  ordinalNumber: string;
  tierType: number;
  status: string;
  vessel: string;
  transportation: string;
  cargoService: string;
  shipmentId: number;
  etdTime: string;
  etaTime: string;
  producer: string;
  shipper: string;
  consignee: string;
  masterBill: string;
  origin: string;
  destination: string;
  volume: number;
}
