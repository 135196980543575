import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Dropdown, Layout, Menu, MenuProps } from "antd";
import { startCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { AvatarIcon, IconMenu, LogoMenu } from "src/assets/icons";
import { USER_TYPE } from "src/constants/login";
import { PATHS } from "src/constants/paths";
import {
  getDecentralization,
  hasStorageJwtToken,
  removeDecentralization,
  removeStorageCurrentEmail,
  removeStorageJwtToken,
  removeStorageRefreshToken,
  removeStorageRememberMe,
} from "src/helpers/storage";
import { DEEP_MENUS, MENUS_ITEM, MENUS_KEY } from "../../constants/sidebar";
import FooterLayout from "./Footer";
import "./styles.scss";

const { Content, Sider } = Layout;

interface ILayoutProps {
  children?: React.ReactNode;
}

const LayoutDefault: React.FC<ILayoutProps> = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const locationState = location?.state as any;
  const { pathname } = location;
  const path = pathname.split("/")[1];
  const ownerAccount = getDecentralization();
  const pathSnippets =
    location.pathname !== "/" ? location.pathname.split("/").filter((i) => i) : [""];
  const breadcrumbItems = pathSnippets?.map((snippet, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
    const title = snippet.replace(/-/g, " ");
    const isLastItem = index === pathSnippets?.length - 1;
    const isSecondLastItem = index === pathSnippets?.length - 2;
    if (isLastItem && locationState?.isHideId) {
      return null;
    } else if (isSecondLastItem && locationState?.isHideId) {
      return (
        <Breadcrumb.Item className="breadcrumb" key={url}>
          {title}
        </Breadcrumb.Item>
      );
    } else if (isLastItem && locationState?.name) {
      return (
        <Breadcrumb.Item className="breadcrumb" key={url}>
          {locationState?.name}
        </Breadcrumb.Item>
      );
    } else {
      if (title === "sub category management") {
        return (
          <Breadcrumb.Item className="breadcrumb" key={url}>
            <a onClick={() => history.goBack()}>{title}</a>
          </Breadcrumb.Item>
        );
      } else {
        return (
          <Breadcrumb.Item className="breadcrumb" key={url}>
            <NavLink to={url}>{title}</NavLink>
          </Breadcrumb.Item>
        );
      }
    }
  });

  const pageTitle = () => {
    let title = pathSnippets.slice(-1)[0].replace(/-/g, " ");
    if (locationState?.isHideId) {
      title = pathSnippets.slice(-2)[0].replace(/-/g, " ");
    }
    return locationState?.name || startCase(title);
  };

  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    let interval = setInterval(async () => {
      if (hasStorageJwtToken()) {
        // dispatch(fetchListUnread());
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const itemBreadcrumb = useMemo(() => {
    const path1 = pathname.split("/")[1];
    const path2 = pathname.split("/")[2];
    const match1 = DEEP_MENUS.find((item) => item.key === path1);
    const match2 = DEEP_MENUS.find((item) => item.key === path2);
    if (match2) {
      // level1
      return match2.name;
    } else if (match1) {
      return match1.name;
    } else {
      const isChild = DEEP_MENUS.find((item) => {
        return item.children.find((sub) => sub.key === path);
      });
      const child = isChild?.children.find((sub) => sub.key === path);
      if (isChild) {
        return child?.label;
      } else {
        return ["Not found"];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, pathname]);
  const goToThePage = (page: string) => {
    if (page.includes("/")) {
      return;
    }
    history.push(`/${page}`);
  };

  const SIDEBAR_MENUS_ADMIN: MenuProps["items"] = [
    {
      icon: MENUS_ITEM.HOME_ADMIN.icon,
      label: MENUS_ITEM.HOME_ADMIN.label,
      key: MENUS_ITEM.HOME_ADMIN.key,
      onClick: () => goToThePage(MENUS_KEY.HOME),
    },
    {
      icon: MENUS_ITEM.GROUP_ADMINISTRATION.icon,
      label: MENUS_ITEM.GROUP_ADMINISTRATION.label,
      key: MENUS_ITEM.GROUP_ADMINISTRATION.key,
      children: MENUS_ITEM.GROUP_ADMINISTRATION.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    Number(ownerAccount) === USER_TYPE.SUPER_ADMIN
      ? {
          icon: MENUS_ITEM.ADMIN_MANAGEMENT.icon,
          label: MENUS_ITEM.ADMIN_MANAGEMENT.label,
          key: MENUS_ITEM.ADMIN_MANAGEMENT.key,
          onClick: () => goToThePage(MENUS_KEY.ADMIN_MANAGEMENT),
        }
      : null,
    {
      icon: MENUS_ITEM.MEMBER_ADMINISTRATION.icon,
      label: MENUS_ITEM.MEMBER_ADMINISTRATION.label,
      key: MENUS_ITEM.MEMBER_ADMINISTRATION.key,
      onClick: () => goToThePage(MENUS_KEY.MEMBER_ADMINISTRATION),
    },
    {
      icon: MENUS_ITEM.ADVERTISEMENT.icon,
      label: MENUS_ITEM.ADVERTISEMENT.label,
      key: MENUS_ITEM.ADVERTISEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.ADVERTISEMENT),
    },
    {
      icon: MENUS_ITEM.TEMPLATE.icon,
      label: MENUS_ITEM.TEMPLATE.label,
      key: MENUS_ITEM.TEMPLATE.key,
      onClick: () => goToThePage(MENUS_KEY.TEMPLATE),
    },
    {
      icon: MENUS_ITEM.NOTIFICATION.icon,
      label: MENUS_ITEM.NOTIFICATION.label,
      key: MENUS_ITEM.NOTIFICATION.key,
      children: MENUS_ITEM.NOTIFICATION.children.map((item) => {
        return {
          key: item.key,
          label: item.label,
          onClick: () => goToThePage(item.path),
        };
      }),
    },
    {
      icon: MENUS_ITEM.CATEGORY_MANAGEMENT.icon,
      label: MENUS_ITEM.CATEGORY_MANAGEMENT.label,
      key: MENUS_ITEM.CATEGORY_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.CATEGORY_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.PRODUCT_MANAGEMENT.icon,
      label: MENUS_ITEM.PRODUCT_MANAGEMENT.label,
      key: MENUS_ITEM.PRODUCT_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.PRODUCT_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.REGION_MANAGEMENT.icon,
      label: MENUS_ITEM.REGION_MANAGEMENT.label,
      key: MENUS_ITEM.REGION_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.REGION_MANAGEMENT),
    },
    {
      icon: MENUS_ITEM.FISH_MARKET_MANAGEMENT.icon,
      label: MENUS_ITEM.FISH_MARKET_MANAGEMENT.label,
      key: MENUS_ITEM.FISH_MARKET_MANAGEMENT.key,
      onClick: () => goToThePage(MENUS_KEY.FISH_MARKET_MANAGEMENT),
    },
  ];

  const logout = () => {
    removeStorageJwtToken();
    removeStorageRefreshToken();
    removeStorageRememberMe();
    removeDecentralization();
    removeStorageCurrentEmail();
    history.push("/login");
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <Link to={PATHS.changePassword()}>Change password</Link>,
    },
    {
      key: "2",
      label: (
        <Link to={PATHS.login()} onClick={logout}>
          Logout
        </Link>
      ),
    },
  ];

  // const handleClick = (item: string) => {
  //   if (path === "report") {
  //     if (item === "Report") {
  //       history.push(`/report`);
  //     }
  //     if (location.pathname.split("/")?.length === 3 && search && item === "Merchant") {
  //       const urlParams = new URLSearchParams(window.location.search);
  //       const merchant_id = urlParams.get("merchant_id");
  //       history.push(`/report/merchant/${merchant_id}`);
  //     }
  //   }
  // };

  const pathLayoutEmpty = () => {
    const paths = [PATHS.logisticInformation(), PATHS.productDetail(), PATHS.changePassword()];
    const isLayoutEmpty = paths.some((url) => pathname.includes(url));
    return isLayoutEmpty;
  };

  return (
    <>
      {hasStorageJwtToken() && pathname !== PATHS.registration() ? (
        <div id="layout">
          {pathLayoutEmpty() ? (
            <Layout>
              <Content>{children}</Content>
            </Layout>
          ) : (
            <Layout className="content-wrapper">
              <div>
                <Sider
                  trigger={null}
                  collapsed={collapsed}
                  collapsible
                  width={233}
                  onCollapse={(value) => setCollapsed(value)}
                  className="sider-container"
                >
                  <div className={`logo-wrapper ${collapsed ? "menu-collapsed" : ""}`}>
                    <img
                      src={LogoMenu}
                      alt=""
                      onClick={() => history.push(PATHS.default())}
                      className={`${collapsed ? "menu-collapsed" : ""}`}
                    />
                    <Button
                      className={`${collapsed ? "btn-collapsed" : "btn-uncollapsed"}`}
                      type="text"
                      icon={collapsed ? <MenuUnfoldOutlined /> : <IconMenu />}
                      onClick={() => setCollapsed(!collapsed)}
                    />
                  </div>
                  <Menu
                    mode="inline"
                    defaultSelectedKeys={[MENUS_KEY.HOME]}
                    defaultOpenKeys={[]}
                    items={SIDEBAR_MENUS_ADMIN}
                    selectedKeys={[path]}
                    // inlineCollapsed={collapsed}
                    theme="dark"
                  />
                </Sider>
              </div>
              <Layout>
                <div className="breadcrumb-container">
                  <div className="breadcrumb-container__header">
                    <div className="title">
                      {pathname === PATHS.default() ? "Welcome!" : pageTitle()}
                    </div>
                    <div>
                      <Dropdown menu={{ items }}>
                        <img src={AvatarIcon} alt="" />
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <Content style={{ padding: "20px 50px" }}>
                  {pathname !== PATHS.default() && (
                    <Breadcrumb className="breadcrumb-container__router">
                      <Breadcrumb.Item className="breadcrumb" onClick={() => history.push("/home")}>
                        Home
                      </Breadcrumb.Item>
                      {breadcrumbItems}
                    </Breadcrumb>
                  )}
                  {children}
                </Content>
                <FooterLayout />
              </Layout>
            </Layout>
          )}
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default LayoutDefault;
