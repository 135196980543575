import { Area, AreaConfig } from "@ant-design/plots";
import { ListItem } from "@antv/component";
import { Skeleton } from "antd";
import BigNumber from "bignumber.js";
import "./styles.scss";
interface Props {
  data: Array<any> | null;
  chartLoading?: boolean;
}
const LineChart = (props: Props) => {
  const { data, chartLoading } = props;
  const config: AreaConfig = {
    data: data as any,
    xField: "time",
    yField: "value",
    xAxis: {
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: "#23262F",
        },
      },
    },
    yAxis: {
      label: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: "#23262F",
        },
        formatter: (text: string, item: ListItem, index: number) => {
          return text;
        },
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom-left",
    },
    point: {
      size: 6,
      shape: "circle",
      style: {
        fill: "#4ECCB4",
      },
    },
    line: {
      style: {
        stroke: "#4ECCB4",
        lineWidth: 3,
      },
    },
    areaStyle: {
      fill: "#4ECCB4",
    },
    smooth: true,
    tooltip: {
      customContent: (title, items) => {
        if (items.length > 0) {
          const { time, value } = items[0]?.data;
          const number = new BigNumber(value).toString();
          return (
            <div className="custom-tooltip">
              <p className="title">{time}</p>
              <div className="content-value">
                <div
                  style={{ backgroundColor: items[0].color }}
                  className="content-value__dot"
                ></div>
                <span className="content-value__value">Value:&nbsp;{number}</span>
              </div>
            </div>
          );
        }
      },
    },
  };
  return (
    <Skeleton loading={chartLoading}>
      <Area {...config} />
    </Skeleton>
  );
};

export default LineChart;
