import BigNumber from "bignumber.js";

export const UPLOAD_TYPE_ALLOW = {
  SVG: "image/svg+xml",
  JPG: "image/jpg",
  JPEG: "image/jpeg",
  PNG: "image/png",
  MP4: "video/mp4",
  PDF: "application/pdf",
  DOC: "application/msword",
  DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const LatLngHK = {
  minLat: 22.1435,
  maxLat: 22.4965,
  minLng: 113.8259,
  maxLng: 114.4214,
};

export const LatLngAU = {
  minLat: -43.634597,
  maxLat: -9.221084,
  minLng: 112.908302,
  maxLng: 153.639131,
};
export const DATE_FORMAT_PRODUCT = "YYYY/MM/DD";
export const GET_ALL = 10000000000;

export const UPLOAD_SIZE_ALLOW = {
  twoMB: 2 * 1024 * 1024,
  fiveMB: 5 * 1024 * 1024,
  tenMB: 10 * 1024 * 1024,
};

export const DEFAULT_MODAL_WIDTH = 528; // unit pixcel

export enum GROUPS_STATUS {
  PENDING = 0,
  APPROVABLE = 1,
  APPROVED = 2,
  REJECTED = 3,
  REVOKED = 4,
  OWNER_REVOKED = 5,
}
export const getStatus = (status: number) => {
  if (status === GROUPS_STATUS.PENDING) {
    return "Pending";
  } else if (status === GROUPS_STATUS.APPROVABLE) {
    return "Available";
  } else if (status === GROUPS_STATUS.APPROVED) {
    return "Available";
  } else if (status === GROUPS_STATUS.REJECTED) {
    return "Rejected";
  } else if (status === GROUPS_STATUS.OWNER_REVOKED) {
    return "Owner Revoked";
  } else if (status === GROUPS_STATUS.REVOKED) {
    return "Revoked";
  }
};

export enum ORDER_STATUS {
  PENDING = 0,
  CONFIRMED = 1,
  DELIVERING = 2,
  DELIVERED = 3,
  CANCELLED = 4,
  REFUNDING = 5,
  REFUNDED = 6,
  SUCCEEDED = 7,
  SETTLED = 8,
  WAITING_FOR_PAYMENT = 9,
  REJECTED = 10,
  EXPIRED = 11,
  ON_GOING = 12,
  COMPLETED = 13,
  USER_PICKED_UP = 14,
  PREPARED = 15,
  PAID = 16,
  REFUND_REJECTED = 17,
}

export const getOrderStatus = (status: number) => {
  if (status === ORDER_STATUS.PENDING) {
    return "Pending";
  } else if (status === ORDER_STATUS.CONFIRMED) {
    return "Confirmed";
  } else if (status === ORDER_STATUS.DELIVERING) {
    return "Delivering";
  } else if (status === ORDER_STATUS.DELIVERED) {
    return "Delivered";
  } else if (status === ORDER_STATUS.CANCELLED) {
    return "Cancelled";
  } else if (status === ORDER_STATUS.REFUNDING) {
    return "Refunding";
  } else if (status === ORDER_STATUS.REFUNDED) {
    return "Refunded";
  } else if (status === ORDER_STATUS.SUCCEEDED) {
    return "Succeeded";
  } else if (status === ORDER_STATUS.SETTLED) {
    return "Settled";
  } else if (status === ORDER_STATUS.WAITING_FOR_PAYMENT) {
    return "Waiting for payment";
  } else if (status === ORDER_STATUS.REFUNDED) {
    return "Refunded";
  } else if (status === ORDER_STATUS.EXPIRED) {
    return "Expired";
  } else if (status === ORDER_STATUS.ON_GOING) {
    return "On going";
  } else if (status === ORDER_STATUS.COMPLETED) {
    return "Completed";
  } else if (status === ORDER_STATUS.USER_PICKED_UP) {
    return "User picked up";
  } else if (status === ORDER_STATUS.PREPARED) {
    return "Prepared";
  } else if (status === ORDER_STATUS.REFUND_REJECTED) {
    return "Refund refused";
  } else {
    return "Paid";
  }
};

export const format2Digit = (value: number | string) => {
  return new BigNumber(value || 0).toFormat(2, 1);
};

export enum MAIN_TAG {
  hot = "Hot",
  best_seller = "Best Seller",
  new = "New Dish",
}

export enum PAGE_STATE {
  NOT_FOUND = "404",
  SUCCESS = "success",
}

export enum NOT_FOUND_MSG {
  PAGE_BROKEN = "May be bigfoot has broken this page. Come back to the homepage",
  APPROVE_CAMERA = "You need to approve browser to use your camera",
  PERMISSION = "You have no permission to view/update information for this product",
  INVALID_QRCODE = "Invalid QR Code",
  TRANSACTION_FAILED = "Transaction to record to Blockchain failed!",
  APPROVAL_TIER_HUB = "You may need approval from TIER HUB team",
  APPROVAL_TIER_GROUP = "You may need approval from your TIER Group Manager",
}

export enum SUCCESS_MSG {
  TRANSACTION_SUCCESS = "Your record is stored on Blockchain",
}

export const formatCountdown = (seconds: number) => {
  return `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0")}:${(seconds % 60).toString().padStart(2, "0")}`;
};

export enum EPRODUCT_GRADE {
  A = "A",
  B = "B",
  C = "C",
}

export enum EPACKAGE_FORM {
  WHOLE = 1,
  FILLETED = 2,
  STEAKS = 3,
  CANNED = 4,
}

export enum ETRANSPORTATION {
  SEA = "Sea",
  AIR = "Air",
  RAIL = "Rail",
  ROAD = "Road",
}

export enum ECARGOSERVICE {
  LCL = "LCL",
  FCL = "FCL",
  LSE = "LSE",
}

export enum EPRODUCT_MEDIA_TYPE {
  PDF = 0,
  IMAGE = 1,
  VIDEO = 2,
}

export const EPRODUCT_GRADE_OPTIONS = [
  {
    value: EPRODUCT_GRADE.A,
    label: EPRODUCT_GRADE.A,
  },
  {
    value: EPRODUCT_GRADE.B,
    label: EPRODUCT_GRADE.B,
  },
  {
    value: EPRODUCT_GRADE.C,
    label: EPRODUCT_GRADE.C,
  },
];

export const EPACKAGE_FORM_OPTIONS = [
  { value: EPACKAGE_FORM.WHOLE, label: "Whole" },
  { value: EPACKAGE_FORM.FILLETED, label: "Filleted" },
  { value: EPACKAGE_FORM.STEAKS, label: "Steaks" },
  { value: EPACKAGE_FORM.CANNED, label: "Canned" },
];

export enum EPREPARATION_FORM {
  RAW = 1,
  COOKED,
  SMOKED,
  LIVE,
  FROZEN,
  DRIED,
  CANNED,
  PICKLED,
}

export const PREPARATION_OPTIONS = [
  {
    value: EPREPARATION_FORM.RAW,
    label: "Raw",
  },
  {
    value: EPREPARATION_FORM.COOKED,
    label: "Cooked",
  },
  {
    value: EPREPARATION_FORM.SMOKED,
    label: "Smoked",
  },
  {
    value: EPREPARATION_FORM.LIVE,
    label: "Live",
  },
  {
    value: EPREPARATION_FORM.FROZEN,
    label: "Frozen",
  },
  {
    value: EPREPARATION_FORM.DRIED,
    label: "Dried",
  },
  {
    value: EPREPARATION_FORM.CANNED,
    label: "Canned",
  },
  {
    value: EPREPARATION_FORM.PICKLED,
    label: "Pickled",
  },
];

export enum ETemplateDataType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DATE_TIME = "DATE_TIME",
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
}

export enum EProductStatus {
  NORMAL = 1,
  REPORTED,
}

export enum ETraceabilityStatus {
  PENDING_SUBMIT = 1,
  SUBMITTING,
  SUBMITTED,
  CONFIRMED,
  FAILED,
}

export enum ENotificationScheduleOption {
  IMMEDIATE = "Immediate send",
  SCHEDULED = "Scheduled date and time",
}

export enum EScheduleType {
  INDIVIDUAL = 1,
  GROUPS,
  TIER,
  ALL,
}

export const RECIPIENTS_OPTIONS = [
  {
    label: "All users",
    value: EScheduleType.ALL,
  },
  {
    label: "Tier",
    value: EScheduleType.TIER,
  },
  {
    label: "Individual",
    value: EScheduleType.INDIVIDUAL,
  },
  {
    label: "Group",
    value: EScheduleType.GROUPS,
  },
];

export enum EScheduleRecurringType {
  NO_REPEAT = 0,
  DAILY = 1,
  WEEKLY = 7,
  MONTHLY = 30,
}

export const RECURRING_TYPE_OPTIONS = [
  {
    label: "Does not repeat",
    value: EScheduleRecurringType.NO_REPEAT,
  },
  {
    label: "Repeat daily",
    value: EScheduleRecurringType.DAILY,
  },
  {
    label: "Repeat weekly",
    value: EScheduleRecurringType.WEEKLY,
  },
  {
    label: "Repeat monthly",
    value: EScheduleRecurringType.MONTHLY,
  },
];

export enum ENotificationChannel {
  EMAIL = "email",
  PUSH_NOTIFICATION = "push_notification",
  IN_APP_NOTIFICATION = "in_app_notification",
}

export const NOTIFICATION_CHANNEL_OPTIONS = [
  {
    label: "Email",
    value: ENotificationChannel.EMAIL,
  },
  {
    label: "In app notification",
    value: ENotificationChannel.IN_APP_NOTIFICATION,
  },
];

export const RECIPIENT_TIER_OPTIONS = [
  {
    label: "Tier 1",
    value: "1",
  },
  {
    label: "Tier 3",
    value: "3",
  },
  {
    label: "Tier 5",
    value: "4",
  },
  {
    label: "Tier 6",
    value: "5",
  },
  {
    label: "Tier 2-4",
    value: "2",
  },
];
