import moment from "moment";
import {
  TxChartForHomePage,
  GenderChartForHomePage,
  CountryChartForHomePage,
} from "src/interfaces/chart";
import { decimal18 } from "./formatNumber";

export const getTimeRangeForChart = (): {
  startTime: string;
  endTime: string;
} => {
  // Get the current date
  const currentDate = moment();

  // Calculate the start time for the past 11 months
  const startTime = currentDate.clone().subtract(11, "months").startOf("month").format();

  // Calculate the end time for the current month
  const endTime = currentDate.clone().endOf("month").format();

  return {
    startTime: startTime,
    endTime: endTime,
  };
};

interface TransformedTxData {
  value: number;
  time: string;
}

// Function to generate the last 12 months with 0 values for missing months
export const generateLast12MonthsForTxChart = (
  apiData: TxChartForHomePage[],
): TransformedTxData[] => {
  const last12MonthsData: TransformedTxData[] = [];
  const currentMonth = moment();
  for (let i = 0; i < 12; i++) {
    const date = currentMonth.clone().subtract(i, "months");
    const apiItem = apiData.find((item) => {
      return item.year === date.year().toString() && item.month === (date.month() + 1).toString();
    });

    if (apiItem) {
      last12MonthsData.push({
        value: Number(apiItem.sum)/decimal18,
        time: date.format("MMM/YYYY"),
      });
    } else {
      last12MonthsData.push({
        value: 0, // Or any default value if data is missing
        time: date.format("MMM/YYYY"),
      });
    }
  }

  // Sort the array in ascending order by the "time" property
  last12MonthsData.sort((a, b) => {
    const dateA = moment(a.time, "MMM/YYYY");
    const dateB = moment(b.time, "MMM/YYYY");
    return dateA.isAfter(dateB) ? 1 : dateA.isBefore(dateB) ? -1 : 0;
  });

  return last12MonthsData;
};

interface TransformedGenderData {
  value: number;
  type: string;
  time: string;
}

export const generateLast12MonthsForGenderChart = (
  apiData: GenderChartForHomePage[],
): TransformedGenderData[] => {
  const transformedData: TransformedGenderData[] = [];
  const currentMonth = moment();
  for (let i = 0; i < 12; i++) {
    const date = currentMonth.clone().subtract(i, "months");
    const apiItem = apiData?.find((item) => {
      return item.year === date.year().toString() && item.month === (date.month() + 1).toString();
    });
    if (apiItem) {
      transformedData.push({
        value: Number(apiItem.male),
        type: "Male",
        time: date.format("MMM/YYYY"),
      });

      transformedData.push({
        value: Number(apiItem.female),
        type: "Female",
        time: date.format("MMM/YYYY"),
      });

      transformedData.push({
        value: Number(apiItem.others),
        type: "Others",
        time: date.format("MMM/YYYY"),
      });
    } else {
      transformedData.push({
        value: 0,
        type: "Male",
        time: date.format("MMM/YYYY"),
      });

      transformedData.push({
        value: 0,
        type: "Female",
        time: date.format("MMM/YYYY"),
      });

      transformedData.push({
        value: 0,
        type: "Others",
        time: date.format("MMM/YYYY"),
      });
    }
  }

  // Sort the array in ascending order by the "time" property
  transformedData.sort((a, b) => {
    const dateA = moment(a.time, "MMM/YYYY");
    const dateB = moment(b.time, "MMM/YYYY");
    return dateA.isAfter(dateB) ? 1 : dateA.isBefore(dateB) ? -1 : 0;
  });

  return transformedData;
};

export const transformCountryData = (
  data: CountryChartForHomePage[],
): CountryChartForHomePage[] => {
  // Sort the data by percentage in descending order
  data.sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage));

  // Extract the top 5 countries by percentage
  const top5Countries = data.slice(0, 5);

  // Calculate the total percentage of the top 5 countries
  const totalPercentage = top5Countries.reduce(
    (sum, country) => sum + parseFloat(country.percentage),
    0,
  );

  // Calculate the percentage for the "others" category
  const othersPercentage = 100 - totalPercentage;

  // Calculate the count for the "others" category
  const othersCount = data
    .slice(5) // Get countries outside the top 5
    .reduce((sum, country) => sum + parseInt(country.count, 10), 0);

  // Create an array with the top 5 countries and the "others" category
  const transformedArray = [
    ...top5Countries,
    {
      countryName: "Others",
      count: othersCount.toString(),
      percentage: othersPercentage.toFixed(2),
    },
  ];

  return transformedArray;
};
