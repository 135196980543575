import loadable, { DefaultComponent } from "@loadable/component";
import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useHistory } from "react-router-dom";
import { PATHS } from "src/constants/paths";
import { hasStorageJwtToken } from "src/helpers/storage";
import HomePageAdmin from "src/pages/home";
import { ExchangePageLoading, MarketPageLoading } from "src/pages/loadings";
import PasswordChanged from "src/pages/login/PasswordChanged";
import { PrivateRoute } from "./components/PrivateRoute";

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.default())) {
    return <ExchangePageLoading />;
  }
  return <MarketPageLoading />;
};

function loadableWFallback(loadFn: (props: {}) => Promise<DefaultComponent<{}>>) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import("./components/PageState"));
const LoginPage = loadableWFallback(() => import("src/pages/login"));
const MemberAdmin = loadableWFallback(() => import("src/pages/MemberAdministration"));
const TemplateAdmin = loadableWFallback(() => import("src/pages/Template"));
const CreateTemplate = loadableWFallback(() => import("src/pages/Template/CreateTemplate"));
const NotificationAdmin = loadableWFallback(() => import("src/pages/Notifications"));
const GroupNotification = loadableWFallback(
  () => import("src/pages/Notifications/GroupNotification"),
);
const CreateNotification = loadableWFallback(
  () => import("src/pages/Notifications/CreateNotification"),
);
const AddGroup = loadableWFallback(() => import("src/pages/Notifications/AddGroup"));

const AdvertisementAdmin = loadableWFallback(() => import("src/pages/Advertisement"));
const CategoryAdmin = loadableWFallback(() => import("src/pages/CategoryManagement"));
const AdminManagement = loadableWFallback(() => import("src/pages/AdminManagement"));
const CreateAdmin = loadableWFallback(() => import("src/pages/AdminManagement/CreateAdmin"));
const ApproveAdmin = loadableWFallback(() => import("src/pages/GroupAdmin/ApproveGroup"));
const GroupDetail = loadableWFallback(() => import("src/pages/GroupAdmin/DetailGroup"));
const GroupAdmin = loadableWFallback(() => import("src/pages/GroupAdmin/ListGroup"));
const ChangePassword = loadableWFallback(() => import("src/pages/home/ChangePassword"));

const RegionManagement = loadableWFallback(() => import("src/pages/RegionManagement"));
const AddNewRegion = loadableWFallback(() => import("src/pages/RegionManagement/add-new-region"));

const CreateCategory = loadableWFallback(
  () => import("src/pages/CategoryManagement/create-category"),
);
const CategoryDetail = loadableWFallback(
  () => import("src/pages/CategoryManagement/category-detail"),
);
const CreateSubCategory = loadableWFallback(
  () => import("src/pages/CategoryManagement/create-sub-category"),
);

const ViewSubCategory = loadableWFallback(
  () => import("src/pages/CategoryManagement/view-sub-category"),
);
const SubCategoryDetail = loadableWFallback(
  () => import("src/pages/CategoryManagement/sub-category-detail"),
);

const FishMarketManagement = loadableWFallback(() => import("src/pages/fish-market-management"));
const AddNewFishMarket = loadableWFallback(
  () => import("src/pages/fish-market-management/create-fish-market"),
);

const ProductManagement = loadableWFallback(() => import("src/pages/product-management"));
const ForgotPassword = loadableWFallback(() => import("src/pages/forgot-password"));
const ForgotSuccess = loadableWFallback(() => import("src/pages/forgot-success"));
const ResetPassword = loadableWFallback(() => import("src/pages/reset-password"));
const ProductDetail = loadableWFallback(
  () => import("src/pages/product-management/product-detail"),
);
const LogisticInformation = loadableWFallback(
  () => import("src/pages/product-management/logistic-information"),
);
const LogisticMap = loadableWFallback(
  () => import("src/pages/product-management/logistic-information/LogisticMap"),
);
const EditLogisticInformation = loadableWFallback(
  () => import("src/pages/product-management/logistic-information/UpdateLogistic"),
);

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <Redirect to={PATHS.login()} />
        }
      />
      <PrivateRoute exact path="/home" component={HomePageAdmin} />
      <Route exact path={PATHS.passwordChanged()} component={PasswordChanged} />
      <Route
        exact
        path={PATHS.login()}
        render={() => (hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <LoginPage />)}
      />
      <Route
        exact
        path={PATHS.forgotPassword()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ForgotPassword />
        }
      />
      <Route
        exact
        path={PATHS.forgotSuccess()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ForgotSuccess />
        }
      />
      <Route
        exact
        path={PATHS.resetPassword()}
        render={() =>
          hasStorageJwtToken() ? <Redirect to={PATHS.default()} /> : <ResetPassword />
        }
      />
      <PrivateRoute exact path={PATHS.changePassword()} component={ChangePassword} />
      {/* Group */}
      <PrivateRoute exact path={PATHS.groupApproveDetail()} component={GroupDetail} />
      <PrivateRoute exact path={PATHS.groupListDetail()} component={GroupDetail} />
      <PrivateRoute exact path={PATHS.approveGroup()} component={ApproveAdmin} />
      <PrivateRoute exact path={PATHS.groupList()} component={GroupAdmin} />
      {/* Admin */}
      <PrivateRoute exact path={PATHS.adminManagement()} component={AdminManagement} />
      <PrivateRoute exact path={PATHS.createAdmin()} component={CreateAdmin} />
      <PrivateRoute exact path={PATHS.memberAdministration()} component={MemberAdmin} />
      {/* Region */}
      <PrivateRoute exact path={PATHS.regionManagement()} component={RegionManagement} />
      <PrivateRoute exact path={PATHS.addRegionManagement()} component={AddNewRegion} />
      <PrivateRoute exact path={PATHS.updateRegionManagement()} component={AddNewRegion} />
      {/* Fish-market */}
      <PrivateRoute exact path={PATHS.fishMarketManagement()} component={FishMarketManagement} />
      <PrivateRoute exact path={PATHS.createFishMarketManagement()} component={AddNewFishMarket} />
      <PrivateRoute exact path={PATHS.updateFishMarketManagement()} component={AddNewFishMarket} />

      {/* Template */}
      <PrivateRoute exact path={PATHS.template()} component={TemplateAdmin} />
      <PrivateRoute exact path={PATHS.createTemplate()} component={CreateTemplate} />
      <PrivateRoute exact path={PATHS.templateDetail()} component={CreateTemplate} />

      <PrivateRoute exact path={PATHS.notification()} component={NotificationAdmin} />
      <PrivateRoute exact path={PATHS.createNotification()} component={CreateNotification} />
      <PrivateRoute exact path={PATHS.notificationDetail()} component={CreateNotification} />
      <PrivateRoute exact path={PATHS.notificationGroup()} component={GroupNotification} />
      <PrivateRoute exact path={PATHS.addGroup()} component={AddGroup} />
      <PrivateRoute exact path={PATHS.editGroup()} component={AddGroup} />

      <PrivateRoute exact path={PATHS.advertisement()} component={AdvertisementAdmin} />
      {/* Product */}
      <PrivateRoute exact path={`${PATHS.productDetail()}/:id`} component={ProductDetail} />
      <PrivateRoute
        exact
        path={`${PATHS.logisticInformation()}/:id`}
        component={LogisticInformation}
      />
      <PrivateRoute
        exact
        path={`${PATHS.editLogisticInformation()}/:id`}
        component={EditLogisticInformation}
      />
      <PrivateRoute exact path={`${PATHS.logisticMap()}/:id`} component={LogisticMap} />
      <PrivateRoute exact path={PATHS.productManagement()} component={ProductManagement} />
      {/* Category */}
      <PrivateRoute exact path={PATHS.createCategory()} component={CreateCategory} />
      <PrivateRoute exact path={PATHS.updateCategory()} component={CreateCategory} />
      <PrivateRoute exact path={PATHS.createSubCategory()} component={CreateSubCategory} />
      <PrivateRoute exact path={PATHS.updateSubCategory()} component={CreateSubCategory} />
      <PrivateRoute exact path={PATHS.viewSubCategory()} component={ViewSubCategory} />
      <PrivateRoute exact path={PATHS.categoryManagement()} component={CategoryAdmin} />
      <PrivateRoute exact path={PATHS.subCategoryDetail()} component={SubCategoryDetail} />
      <PrivateRoute exact path={PATHS.categoryDetail()} component={CategoryDetail} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
