import { Pie, PieConfig } from "@ant-design/plots";
import { Skeleton } from "antd";
interface Props {
  data: Array<any> | null;
  chartLoading?: boolean;
}

const PieChart = (props: Props) => {
  const { data, chartLoading } = props;
  const config: PieConfig = {
    appendPadding: 10,
    data: data as any,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      type: "inner",
      offset: "-20%",
      content: (value: any) => {
        return `${value.value.toString()}%`;
      },
      style: {
        fontSize: 10,
        textAlign: "center",
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
      flipPage: false,
      itemWidth: 90,
      itemName: {
        style: {
          fontSize: 12,
          fontWeight: 400,
          fill: "#23262F",
        },
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
    color: ["#EA564C", "#239695", "#917EAD", "#51B8C1", "#EE8D7D", "#FFD166"],
    pieStyle: {
      stroke: "transparent",
    },
    tooltip: {
      customContent: (title, items) => {
        if (items.length > 0) {
          const { type, count } = items[0]?.data;
          return (
            <div className="custom-tooltip-pie-chart">
              <div style={{ backgroundColor: items[0].color }} className="dot"></div>
              <p className="title">
                {type}:&nbsp;{count}
              </p>
            </div>
          );
        }
      },
    },
  };

  return (
    <div>
      <Skeleton loading={chartLoading}>
        <Pie {...config} />
      </Skeleton>
    </div>
  );
};

export default PieChart;
