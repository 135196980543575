import { LogLevel } from "@azure/msal-browser";
import { CLIENT_ID_MS, TENANT_MS } from "src/constants/env";

export const config_login_microsoft = {
  auth: {
    clientId: CLIENT_ID_MS,
    authority: `https://login.microsoft.com/${TENANT_MS}`,
    // redirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
