export const PATHS = {
  default: () => "/home",
  notification: () => "/notification",
  notificationGroup: () => "/notification-group",
  login: () => "/login",
  productManagement: () => "/product-management",
  regionManagement: () => "/region-management",
  addRegionManagement: () => "/region-management/add-new-region",
  updateRegionManagement: () => "/region-management/edit-region/:id",
  fishMarketManagement: () => "/fish-market-management",
  createFishMarketManagement: () => "/fish-market-management/create-fish-market",
  updateFishMarketManagement: () => "/fish-market-management/edit-fish-market/:id",
  productDetail: () => "/product-detail",
  productView: () => "/product-management",
  logisticInformation: () => "/logistic-information",
  editLogisticInformation: () => "/logistic-information/edit",
  logisticMap: () => "/logistic-information/map",
  configMenu: () => `/config-menu/:index`,
  forgotPassword: () => "/forgot-password",
  forgotSuccess: () => "/forgot-success",
  resetPassword: () => "/auth/reset-password",
  lpCashOut: () => "/lp-management",
  registration: () => "/registration",
  categoryView: () => "/category-view/:id",
  listQRCode: () => "/qr-code",
  groupInfo: () => "/group-information",
  adminManagement: () => "/admin-management",
  createAdmin: () => "/admin-management/create-new-admin",
  memberAdministration: () => "/member-administration",
  categoryManagement: () => "/category-management",
  approveGroup: () => "/approve-group",
  groupList: () => "/group-list",
  groupApproveDetail: () => "/approve-group/:id",
  groupListDetail: () => "/group-list/:id",
  updateProduct: () => "/product/update",
  changePassword: () => "/change-password",
  passwordChanged: () => "/password-changed",
  template: () => "/template",
  createTemplate: () => "/template/create-template",
  templateDetail: () => "/template/edit-template/:id",
  advertisement: () => "/advertisement",
  setting: () => "/setting",
  createCategory: () => "/category-management/create-category",
  updateCategory: () => "/category-management/edit-category/:id",
  categoryDetail: () => "/category-management/sub-category-management/:id",
  subCategoryDetail: () => "/category-management/sub-category-management/product-list/:id",
  createSubCategory: () => "/category-management/sub-category-management/create-sub-category",
  updateSubCategory: () => "/category-management/sub-category-management/edit-sub-category/:id",
  viewSubCategory: () => "/category-management/sub-category-management/view/:id",
  createNotification: () => "/notification/create-notification",
  notificationDetail: () => "/notification/edit-notification/:id",
  addGroup: () => "/notification-group/add-group",
  editGroup: () => "/notification-group/edit-group/:id",
};
