import { Card, Col, Row } from "antd";
import ColumnChart from "src/components/18.chart/ColumnChart";
import LineChart from "src/components/18.chart/LineChart";
import PieChart from "src/components/18.chart/PieChart";
import "./styles.scss";
import {
  useCountryChartForHomePageData,
  useGenderChartForHomePageData,
  useTxChartForHomePageData,
} from "src/hooks/useFetchChart";
import { EChartUnit } from "src/services/params-type";

import { useMemo } from "react";
import {
  getTimeRangeForChart,
  generateLast12MonthsForTxChart,
  generateLast12MonthsForGenderChart,
} from "src/helpers/chart";
import { decimal18, nFormatter } from "src/helpers/formatNumber";
import "./styles.scss";
import BigNumber from "bignumber.js";

export const routes = [
  {
    path: "/",
    breadcrumbName: "Home",
  },
];
const HomePage = () => {
  const { data: txData, loading: txLoading } = useTxChartForHomePageData({
    startTime: getTimeRangeForChart().startTime,
    endTime: getTimeRangeForChart().endTime,
    unit: EChartUnit.MONTH,
  });

  const { data: countryData, loading: countryDataLoading } = useCountryChartForHomePageData();

  const { data: genderData, loading: genderDataLoading } = useGenderChartForHomePageData({
    startTime: getTimeRangeForChart().startTime,
    endTime: getTimeRangeForChart().endTime,
    unit: EChartUnit.MONTH,
  });

  const countryChartData = useMemo(
    () =>
      countryData &&
      countryData?.map((data) => ({
        value: Number(data?.percentage),
        type: data?.countryName,
        count: Number(data?.count),
      })),
    [countryData],
  );

  const txChartData = useMemo(() => txData && generateLast12MonthsForTxChart(txData), [txData]);

  const txGenderData = useMemo(
    () => genderData && generateLast12MonthsForGenderChart(genderData),
    [genderData],
  );

  const totalRegistrationsByCountry = useMemo(
    () => countryData && countryData.reduce((sum, country) => sum + parseInt(country.count, 10), 0),
    [countryData],
  );

  const totalRegistrationsByGender = useMemo(
    () => txGenderData && txGenderData.reduce((sum, country) => sum + country.value, 0),
    [txGenderData],
  );

  const totalMaticTx = useMemo(
    () => txData && txData.reduce((sum, tx) => sum + parseInt(tx.sum, 10), 0),
    [txData],
  );

  const totalFeeMATIC = new BigNumber((totalMaticTx || 0) / decimal18).toLocaleString();

  return (
    <div className="home-wrapper">
      <div className="product-wrapper">
        <Row gutter={[16, 24]}>
          <Col span={24}>
            <Row gutter={[16, 24]}>
              <Col span={6}>
                <Card
                  className="chart-card"
                  title={
                    <>
                      <h4 className="chart-title">Registration by Countries</h4>
                      <span className="chart-amount">
                        {totalRegistrationsByCountry?.toLocaleString()}
                      </span>
                    </>
                  }
                >
                  <PieChart data={countryChartData} chartLoading={countryDataLoading} />
                </Card>
              </Col>
              <Col span={18}>
                <Card
                  className="chart-card"
                  title={
                    <>
                      <h4 className="chart-title">Registration over 12 months by genders</h4>
                      <span className="chart-sub-title">
                        {" "}
                        {totalRegistrationsByGender
                          ? nFormatter(totalRegistrationsByGender?.toString())
                          : "0"}{" "}
                        Registrations in past 12 months
                      </span>
                    </>
                  }
                >
                  <ColumnChart data={txGenderData} chartLoading={genderDataLoading} />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Card
              className="chart-card"
              title={
                <>
                  <h4 className="chart-title">Transaction fees paid over 12 months</h4>
                  <span className="chart-sub-title">
                    {totalFeeMATIC} MATIC has been paid in past 12 months
                  </span>
                </>
              }
            >
              <LineChart data={txChartData} chartLoading={txLoading} />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HomePage;
